export const METERS_BATCH_DELETE_REQUEST = 'METERS_BATCH_DELETE_REQUEST'
export const METERS_BATCH_DELETE_SUCCESS = 'METERS_BATCH_DELETE_SUCCESS'
export const METERS_BATCH_DELETE_FAILURE = 'METERS_BATCH_DELETE_FAILURE'

export const METERS_FETCH_MANY_REQUEST = 'METERS_FETCH_MANY_REQUEST'
export const METERS_FETCH_MANY_SUCCESS = 'METERS_FETCH_MANY_SUCCESS'
export const METERS_FETCH_MANY_FAILURE = 'METERS_FETCH_MANY_FAILURE'

export const METERS_FETCH_REQUEST = 'METERS_FETCH_REQUEST'
export const METERS_FETCH_SUCCESS = 'METERS_FETCH_SUCCESS'
export const METERS_FETCH_FAILURE = 'METERS_FETCH_FAILURE'

export const METERS_SAVE_REQUEST = 'METERS_SAVE_REQUEST'
export const METERS_SAVE_SUCCESS = 'METERS_SAVE_SUCCESS'
export const METERS_SAVE_FAILURE = 'METERS_SAVE_FAILURE'
