export const ENERGY_SITES_BATCH_DELETE_REQUEST = 'ENERGY_SITES_BATCH_DELETE_REQUEST'
export const ENERGY_SITES_BATCH_DELETE_SUCCESS = 'ENERGY_SITES_BATCH_DELETE_SUCCESS'
export const ENERGY_SITES_BATCH_DELETE_FAILURE = 'ENERGY_SITES_BATCH_DELETE_FAILURE'

export const ENERGY_SITES_FETCH_MANY_REQUEST = 'ENERGY_SITES_FETCH_MANY_REQUEST'
export const ENERGY_SITES_FETCH_MANY_SUCCESS = 'ENERGY_SITES_FETCH_MANY_SUCCESS'
export const ENERGY_SITES_FETCH_MANY_FAILURE = 'ENERGY_SITES_FETCH_MANY_FAILURE'

export const ENERGY_SITES_FETCH_REQUEST = 'ENERGY_SITES_FETCH_REQUEST'
export const ENERGY_SITES_FETCH_SUCCESS = 'ENERGY_SITES_FETCH_SUCCESS'
export const ENERGY_SITES_FETCH_FAILURE = 'ENERGY_SITES_FETCH_FAILURE'

export const ENERGY_SITES_SAVE_REQUEST = 'ENERGY_SITES_SAVE_REQUEST'
export const ENERGY_SITES_SAVE_SUCCESS = 'ENERGY_SITES_SAVE_SUCCESS'
export const ENERGY_SITES_SAVE_FAILURE = 'ENERGY_SITES_SAVE_FAILURE'
