import {
  METERS_BATCH_DELETE_REQUEST,
  METERS_BATCH_DELETE_SUCCESS,
  METERS_BATCH_DELETE_FAILURE,
  METERS_FETCH_MANY_REQUEST,
  METERS_FETCH_MANY_SUCCESS,
  METERS_FETCH_MANY_FAILURE,
  METERS_FETCH_REQUEST,
  METERS_FETCH_SUCCESS,
  METERS_FETCH_FAILURE,
  METERS_SAVE_REQUEST,
  METERS_SAVE_SUCCESS,
  METERS_SAVE_FAILURE
} from './types'

export default {
  reset ({ commit }) {
    commit(METERS_FETCH_SUCCESS, { data: {} })
  },
  deleteManyMeters ({ commit }, { ids = [] }) {
    commit(METERS_BATCH_DELETE_REQUEST)
    return window.axios.post('/meters', { _method: 'DELETE', ids }).then(response => {
      commit(METERS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(METERS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManyMeters ({ commit }, { siteId = '', query = '', page = 1 }) {
    commit(METERS_FETCH_MANY_REQUEST)
    return window.axios.get('/meters', { params: { site_id: siteId, query, page } }).then(response => {
      commit(METERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(METERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchMeter ({ commit }, id) {
    commit(METERS_FETCH_REQUEST)
    return window.axios.get(`/meters/${id}`).then(response => {
      commit(METERS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(METERS_FETCH_FAILURE, error)
      throw error
    })
  },
  saveMeter ({ commit }, payload) {
    const path = payload.id ? `/meters/${payload.id}` : '/meters'
    const method = payload.id ? 'put' : 'post'

    commit(METERS_SAVE_REQUEST)
    return window.axios[method](path, payload).then(response => {
      commit(METERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(METERS_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(METERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  }
}
