export const ENERGY_CUSTOMERS_BATCH_DELETE_REQUEST = 'ENERGY_CUSTOMERS_BATCH_DELETE_REQUEST'
export const ENERGY_CUSTOMERS_BATCH_DELETE_SUCCESS = 'ENERGY_CUSTOMERS_BATCH_DELETE_SUCCESS'
export const ENERGY_CUSTOMERS_BATCH_DELETE_FAILURE = 'ENERGY_CUSTOMERS_BATCH_DELETE_FAILURE'

export const ENERGY_CUSTOMERS_FETCH_MANY_REQUEST = 'ENERGY_CUSTOMERS_FETCH_MANY_REQUEST'
export const ENERGY_CUSTOMERS_FETCH_MANY_SUCCESS = 'ENERGY_CUSTOMERS_FETCH_MANY_SUCCESS'
export const ENERGY_CUSTOMERS_FETCH_MANY_FAILURE = 'ENERGY_CUSTOMERS_FETCH_MANY_FAILURE'

export const ENERGY_CUSTOMERS_SAVE_NOTE_REQUEST = 'ENERGY_CUSTOMERS_SAVE_NOTE_REQUEST'
export const ENERGY_CUSTOMERS_SAVE_NOTE_SUCCESS = 'ENERGY_CUSTOMERS_SAVE_NOTE_SUCCESS'
export const ENERGY_CUSTOMERS_SAVE_NOTE_FAILURE = 'ENERGY_CUSTOMERS_SAVE_NOTE_FAILURE'

export const ENERGY_CUSTOMERS_UPLOAD_REQUEST = 'ENERGY_CUSTOMERS_UPLOAD_REQUEST'
export const ENERGY_CUSTOMERS_UPLOAD_SUCCESS = 'ENERGY_CUSTOMERS_UPLOAD_SUCCESS'
export const ENERGY_CUSTOMERS_UPLOAD_FAILURE = 'ENERGY_CUSTOMERS_UPLOAD_FAILURE'

export const ENERGY_CUSTOMERS_FETCH_REQUEST = 'ENERGY_CUSTOMERS_FETCH_REQUEST'
export const ENERGY_CUSTOMERS_FETCH_SUCCESS = 'ENERGY_CUSTOMERS_FETCH_SUCCESS'
export const ENERGY_CUSTOMERS_FETCH_FAILURE = 'ENERGY_CUSTOMERS_FETCH_FAILURE'

export const ENERGY_CUSTOMERS_SAVE_REQUEST = 'ENERGY_CUSTOMERS_SAVE_REQUEST'
export const ENERGY_CUSTOMERS_SAVE_SUCCESS = 'ENERGY_CUSTOMERS_SAVE_SUCCESS'
export const ENERGY_CUSTOMERS_SAVE_FAILURE = 'ENERGY_CUSTOMERS_SAVE_FAILURE'
