import {
  ENERGY_CUSTOMERS_BATCH_DELETE_REQUEST, ENERGY_CUSTOMERS_BATCH_DELETE_SUCCESS, ENERGY_CUSTOMERS_BATCH_DELETE_FAILURE,
  ENERGY_CUSTOMERS_FETCH_MANY_REQUEST, ENERGY_CUSTOMERS_FETCH_MANY_SUCCESS, ENERGY_CUSTOMERS_FETCH_MANY_FAILURE,
  ENERGY_CUSTOMERS_SAVE_NOTE_REQUEST, ENERGY_CUSTOMERS_SAVE_NOTE_SUCCESS, ENERGY_CUSTOMERS_SAVE_NOTE_FAILURE,
  ENERGY_CUSTOMERS_UPLOAD_REQUEST, ENERGY_CUSTOMERS_UPLOAD_SUCCESS, ENERGY_CUSTOMERS_UPLOAD_FAILURE,
  ENERGY_CUSTOMERS_FETCH_REQUEST, ENERGY_CUSTOMERS_FETCH_SUCCESS, ENERGY_CUSTOMERS_FETCH_FAILURE,
  ENERGY_CUSTOMERS_SAVE_REQUEST, ENERGY_CUSTOMERS_SAVE_SUCCESS, ENERGY_CUSTOMERS_SAVE_FAILURE
} from './types'

export default {
  reset ({ commit }) {
    commit(ENERGY_CUSTOMERS_FETCH_SUCCESS, { data: {} })
  },
  deleteManyCustomers ({ commit }, { ids = [] }) {
    commit(ENERGY_CUSTOMERS_BATCH_DELETE_REQUEST)
    return window.axios.post('/energy-customers', { _method: 'DELETE', ids }).then(response => {
      commit(ENERGY_CUSTOMERS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ENERGY_CUSTOMERS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManyCustomers ({ commit }, { query = '', page = 1, showLeads = false }) {
    commit(ENERGY_CUSTOMERS_FETCH_MANY_REQUEST)
    return window.axios.get('/energy-customers', { params: { query, page, showLeads } }).then(response => {
      commit(ENERGY_CUSTOMERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ENERGY_CUSTOMERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchCustomer ({ commit }, id) {
    commit(ENERGY_CUSTOMERS_FETCH_REQUEST)
    return window.axios.get(`/energy-customers/${id}`).then(response => {
      commit(ENERGY_CUSTOMERS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ENERGY_CUSTOMERS_FETCH_FAILURE, error)
      throw error
    })
  },
  saveCustomer ({ commit }, user) {
    const path = user.id ? `/energy-customers/${user.id}` : '/energy-customers'
    const method = user.id ? 'put' : 'post'

    commit(ENERGY_CUSTOMERS_SAVE_REQUEST)
    return window.axios[method](path, user).then(response => {
      commit(ENERGY_CUSTOMERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(ENERGY_CUSTOMERS_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(ENERGY_CUSTOMERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  createNote ({ commit }, { customerId, content }) {
    commit(ENERGY_CUSTOMERS_SAVE_NOTE_REQUEST)
    return window.axios.post('/energy-customer-notes', { content, customer_id: customerId }).then(response => {
      commit(ENERGY_CUSTOMERS_SAVE_NOTE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(ENERGY_CUSTOMERS_SAVE_NOTE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(ENERGY_CUSTOMERS_SAVE_NOTE_FAILURE, { error })
      }
      throw error
    })
  },
  uploadDocument ({ commit }, { id, data }) {
    commit(ENERGY_CUSTOMERS_UPLOAD_REQUEST)
    return window.axios.post(`/energy-customers/${id}/upload`, data).then(response => {
      commit(ENERGY_CUSTOMERS_UPLOAD_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(ENERGY_CUSTOMERS_UPLOAD_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(ENERGY_CUSTOMERS_UPLOAD_FAILURE, { error })
      }
      throw error
    })
  }
}
