<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Meter</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-card class="mb-4">
                <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                  <div class="flex-fill" v-if="!selectedCustomer || selectedCustomer.id !== this.customer_id">
                    <p class="h3 font-weight-bold text-muted">Assign a customer</p>
                    <p class="mb-0 text-muted">A meter must belong to a customer.</p>
                  </div>
                  <div class="flex-fill mr-2 minw-0" v-if="(selectedCustomer && selectedCustomer.id) === this.customer_id">
                    <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selectedCustomer.company_name }}</p>
                    <p class="mb-0 text-muted">{{ selectedCustomer.address && selectedCustomer.address.address_snippet }}</p>
                  </div>
                  <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
                    <b-button block class="text-nowrap" variant="secondary" v-b-modal.assign-customer>Assign Customer</b-button>
                  </div>
                </b-card-text>
              </b-card>
              <b-modal id="assign-customer" size="lg" cancel-title="Close" ok-title="Assign"
                title="Assign Customer" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedCustomer"
                @ok="customer_id = selectedCustomer.id" body-class="p-0">
                <div class="p-3 pb-0">
                  <b-form-group class="mb-0" label="Customer Search" label-for="customer-search" label-sr-only>
                    <b-form-input debounce="500" @update="fetchManyCustomers({ query: customerSearchQuery })" id="customer-search" v-model="customerSearchQuery" v-b-popover.hover.top="'Search for customers that have already been added to the system.'" />
                  </b-form-group>
                </div>
                <div class="text-center mt-2 mb-4" v-if="isSearching">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group class="border-top" flush v-if="!isSearching && customerSearchResults && customerSearchResults.length">
                  <b-list-group-item button @click.prevent="selectedCustomer = customer" :class="{'bg-selected': customer.id === (selectedCustomer && selectedCustomer.id)}" :key="customer.id" v-for="customer in customerSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ customer.company_name }}</p>
                      <p class="text-muted mb-0"><small>{{ customer.company_number }} - {{ customer.address && customer.address.address_snippet }}</small></p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </b-col>
            <b-col cols="12" md="6">
              <b-card class="mb-4">
                <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                  <div class="flex-fill" v-if="!selectedSite || selectedSite.id !== this.site_id">
                    <p class="h3 font-weight-bold text-muted">Assign a site</p>
                    <p class="mb-0 text-muted">A meter must belong to a site.</p>
                  </div>
                  <div class="flex-fill mr-2 minw-0" v-if="(selectedSite && selectedSite.id) === this.site_id">
                    <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selectedSite.address && selectedSite.address.postcode }}</p>
                    <p class="mb-0 text-muted">{{ selectedSite.address && selectedSite.address.address_snippet }}</p>
                  </div>
                  <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
                    <b-button block :disabled="!this.customer_id" class="text-nowrap" variant="secondary" v-b-modal.assign-site>Assign Site</b-button>
                  </div>
                </b-card-text>
              </b-card>
              <b-modal id="assign-site" size="lg" cancel-title="Close" ok-title="Assign"
                title="Assign Site" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedSite"
                @ok="site_id = selectedSite.id" body-class="p-0">
                <div class="text-center my-4" v-if="isSearchingSites">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group flush v-if="!isSearchingSites && siteSearchResults && siteSearchResults.length">
                  <b-list-group-item button @click.prevent="selectedSite = site" :class="{'bg-selected': site.id === (selectedSite && selectedSite.id)}" :key="site.id" v-for="site in siteSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ site.customer.company_name }} ({{ site.address && site.address.postcode }})</p>
                      <p class="text-muted mb-0"><small>{{ site.customer.company_number }} - {{ site.address && site.address.address_snippet }}</small></p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-card-text class="d-flex flex-column flex-md-row justify-space-between">
                  <div class="align-self-center d-flex flex-row flex-fill mb-4 mb-md-0 mt-3">
                    <div class="align-self-center mr-2" v-if="type !== null">
                      <b-icon font-scale="2" icon="lightning-fill" variant="warning" v-if="type === 'e'" />
                      <b-icon font-scale="2" icon="sun" variant="danger" v-if="type === 'g'" />
                      <b-icon font-scale="2" icon="droplet-half" variant="info" v-if="type === 'w'" />
                    </div>
                    <div class="align-self-center flex-fill">
                      <b-dropdown :text="meterType !== null ? meterType : 'Select Meter Type'" variant="light">
                        <b-dd-item-button @click.prevent="type = 'e'">Electric</b-dd-item-button>
                        <b-dd-item-button @click.prevent="type = 'g'">Gas</b-dd-item-button>
                        <b-dd-item-button @click.prevent="type = 'w'">Water</b-dd-item-button>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="align-self-center w-100 w-md-50">
                    <b-form-group class="mb-0" :invalid-feedback="validationInvalidFeedback(errors, 'mpan')" :state="validationState(errors, 'mpan')" v-if="type === 'e'">
                      <template #label>
                        MPAN <b-icon class="text-secondary" icon="info-square-fill" title="Meter Point Administration Number" v-b-popover.hover.righttop="'Consists of a bunch of digits which uniquely identify an electricity supply, where it is located, who supplies it, and how much is required.'"></b-icon>
                      </template>
                      <div class="d-flex flex-row">
                        <div class="d-none d-md-block mpan-prepend">S</div>
                        <div class="d-flex flex-column">
                          <b-input-group class="mpan-top-row">
                            <b-form-input class="text-center text-monospace" :disabled="isSaving" type="text" v-model="mpan[0]" />
                            <b-form-input class="text-center text-monospace" :disabled="isSaving" type="text" v-model="mpan[1]" />
                            <b-form-input class="text-center text-monospace" :disabled="isSaving" type="text" v-model="mpan[2]" />
                          </b-input-group>
                          <b-input-group class="mpan-bottom-row">
                            <b-form-input class="text-center text-monospace" :disabled="isSaving" type="text" v-model="mpan[3]" />
                            <b-form-input class="text-center text-monospace w-50" :disabled="isSaving" type="text" v-model="mpan[4]" />
                            <b-form-input class="text-center text-monospace" :disabled="isSaving" type="text" v-model="mpan[5]" />
                          </b-input-group>
                        </div>
                      </div>
                      <p class="text-muted mb-0 mt-1"><small>Only the bottom line (MPAN Core) is required. The top line is not required, but it's advised to still attempt entry of the top line.</small></p>
                    </b-form-group>
                    <b-form-group label="MPRN" :invalid-feedback="validationInvalidFeedback(errors, 'mprn')" v-if="type === 'g'">
                      <template #label>
                        MPRN <b-icon class="text-secondary" icon="info-square-fill" title="Meter Point Reference Number" v-b-popover.hover.righttop="'A ten character code with identifies a gas supply.'"></b-icon>
                      </template>
                      <b-form-input :disabled="isSaving" type="text" :state="validationState(errors, 'mprn')" v-model="mprn" />
                    </b-form-group>
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors, 'spid')" v-if="type === 'w'">
                      <template #label>
                        SPID <b-icon class="text-secondary" icon="info-square-fill" title="Supply Point Identifier" v-b-popover.hover.righttop="'A ten character code which identifies a sewerage/water supply. The 8th digit is W for water, S for sewerage.'"></b-icon>
                      </template>
                      <b-form-input :disabled="isSaving" type="text" :state="validationState(errors, 'spid')" v-model="spid" />
                    </b-form-group>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Annual Consumption" :invalid-feedback="validationInvalidFeedback(errors, 'aq')" :state="validationState(errors, 'aq')">
                <b-input-group :append="type === 'w' ? 'm³' : 'kWh'">
                  <b-form-input :disabled="isSaving" type="number" v-model.number="consumption" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Original Supplier" :invalid-feedback="validationInvalidFeedback(errors, 'original_supplier')" :state="validationState(errors, 'original_supplier')">
                <b-form-input :disabled="isSaving" type="text" v-model="originalSupplier" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Original Supplier CED" :invalid-feedback="validationInvalidFeedback(errors, 'original_end_date')" :state="validationState(errors, 'original_end_date')">
                <b-form-datepicker :disabled="isSaving" v-model="originalSupplierCed" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block @click.prevent="addNewMeter" class="mb-3" type="submit" variant="primary" v-if="$route.params.id">Add Another</b-button>
              <b-button block class="mb-3" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

const initialData = () => ({
  mpan: [],
  mprn: '',
  spid: '',
  type: 'e',
  consumption: null,
  originalSupplier: null,
  originalSupplierCed: null,
  site_id: 0,
  customer_id: 0,
  selectedSite: {},
  selectedCustomer: {},
  siteSearchQuery: null,
  customerSearchQuery: null
})

export default {
  mixins: [validation],
  created () {
    if (this.$route.params.id) {
      this.fetchMeter(this.$route.params.id)
    }
  },
  data () {
    return initialData()
  },
  beforeRouteLeave (_0, _1, next) {
    this.$store.dispatch('meters/reset')
    Object.assign(this.$data, initialData())
    return next()
  },
  computed: {
    ...mapGetters('energy-customers', { customerSearchResults: 'data', isSearching: 'isLoading' }),
    ...mapGetters('energy-sites', { siteSearchResults: 'data', isSearchingSites: 'isLoading' }),
    ...mapGetters('meters', ['errors', 'isLoading', 'isSaving', 'single']),
    meterTypeApi () {
      switch (this.type) {
        case 'e': return 'electric'
        case 'w': return 'water'
        case 'g': return 'gas'
        default: return null
      }
    },
    meterType () {
      switch (this.type) {
        case 'e': return 'Electric'
        case 'g': return 'Gas'
        case 'w': return 'Water'
        default: return null
      }
    }
  },
  methods: {
    ...mapActions('energy-customers', ['fetchManyCustomers']),
    ...mapActions('energy-sites', ['fetchManySites']),
    ...mapActions('meters', ['fetchMeter', 'saveMeter']),
    addNewMeter () {
      this.mpan = []
      this.mprn = ''
      this.spid = ''
      this.type = 'e'
      this.consumption = null
      this.originalSupplier = null
      this.originalSupplierCed = null
      this.$router.push({ name: 'energy.meters.create', query: { customer: this.customer_id, site: this.site_id } })
    },
    submit () {
      this.saveMeter({
        id: this.$route.params.id,
        type: this.meterTypeApi,
        top_line: this.mpan.slice(0, 3).join('').replace(/\s+/g, ''),
        mpan_core: this.mpan.slice(3).join('').replace(/\s+/g, ''),
        mprn: this.mprn,
        spid: this.spid,
        site_id: this.site_id,
        customer_id: this.customer_id,
        consumption: this.consumption,
        original_supplier: this.originalSupplier,
        original_end_date: this.originalSupplierCed
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Meter ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This meter has successfully been saved.'
        })

        if (!this.$route.params.id) {
          return this.$router.push({ name: 'energy.meters.update', params: { id: response.data.id } })
        }

        return this.fetchMeter(this.$route.params.id)
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    customer_id (value) {
      this.fetchManySites({ customerId: value })
    },
    single (value) {
      this.site_id = get(value, 'site.id', null)
      this.selectedSite = get(value, 'site', {})
      this.customer_id = get(value, 'site.customer.id', null)
      this.selectedCustomer = get(value, 'site.customer', {})

      const topLine = get(value, 'top_line')
      const mpanCore = get(value, 'mpan_core')
      if (topLine || mpanCore) {
        this.mpan = [
          topLine?.slice(0, 1), topLine?.slice(1, 4), topLine?.slice(4, 7),
          mpanCore?.slice(0, 2), mpanCore?.slice(2, 10), mpanCore?.slice(10, 13)
        ]
      }

      this.mprn = get(value, 'mprn')
      this.spid = get(value, 'spid')
      this.consumption = get(value, 'consumption')
      this.originalSupplier = get(value, 'original_supplier')
      this.originalSupplierCed = get(value, 'original_end_date')
      const type = get(value, 'type')
      if (type) {
        this.type = type?.slice(0, 1)
      }
    }
  }
}
</script>

<style>

</style>
