<template>
  <b-container>
    <ResourceList
      can-delete create-to="energy.meters.create" resource="meter" soft-deletes title="Meters"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #buttons>
        <!-- <b-dd-item-button :disabled="currentUserCan(permissions.CAN_SEND_PASSWORD_RESET_EMAIL)">Send Password Reset Email</b-dd-item-button> -->
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'energy.meters.update', params: { id: item.id }}"><b-icon :class="`text-${meterIconColor(item)}`" :icon="meterIcon(item)" v-b-popover.hover.left="item.type.charAt(0).toUpperCase() + item.type.slice(1)" /> {{ (item.site && item.site.customer && item.site.customer.company_name) || 'Unknown Customer Name' }} ({{ (item.site && item.site.address && item.site.address.postcode) || 'Unknown Postcode' }})</router-link></p>
            <FriendlyDate class="align-self-end mb-1 text-muted" :date="item.created_at" />
          </div>
          <div class="d-flex flex-row flex-fill mb-0">
            <p class="mb-0 flex-fill text-muted">{{ item.consumption }} {{ item.type === 'water' ? 'm³' : 'kWh' }} estimated quota &bull; {{ (item.site && item.site.address && item.site.address.address_snippet) || 'Unknown Address' }}</p>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchManyMeters(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('meters', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('meters', ['deleteManyMeters', 'fetchManyMeters']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteManyMeters({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyMeters(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyMeters({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyMeters({ ...this.$route.query, query })
      }
    },
    meterIcon (item) {
      if (item.type === 'electric') {
        return 'lightning-fill'
      } else if (item.type === 'water') {
        return 'droplet-half'
      } else if (item.type === 'gas') {
        return 'sun'
      }
    },
    meterIconColor (item) {
      if (item.type === 'electric') {
        return 'warning'
      } else if (item.type === 'gas') {
        return 'danger'
      } else if (item.type === 'water') {
        return 'info'
      }
    }
  }
}
</script>

<style>

</style>
