import {
  ENERGY_SITES_BATCH_DELETE_REQUEST,
  ENERGY_SITES_BATCH_DELETE_SUCCESS,
  ENERGY_SITES_BATCH_DELETE_FAILURE,
  ENERGY_SITES_FETCH_MANY_REQUEST,
  ENERGY_SITES_FETCH_MANY_SUCCESS,
  ENERGY_SITES_FETCH_MANY_FAILURE,
  ENERGY_SITES_FETCH_REQUEST,
  ENERGY_SITES_FETCH_SUCCESS,
  ENERGY_SITES_FETCH_FAILURE,
  ENERGY_SITES_SAVE_REQUEST,
  ENERGY_SITES_SAVE_SUCCESS,
  ENERGY_SITES_SAVE_FAILURE
} from './types'

export default {
  reset ({ commit }) {
    commit(ENERGY_SITES_FETCH_SUCCESS, { data: {} })
  },
  deleteManySites ({ commit }, { ids = [] }) {
    commit(ENERGY_SITES_BATCH_DELETE_REQUEST)
    return window.axios.post('/energy-sites', { _method: 'DELETE', ids }).then(response => {
      commit(ENERGY_SITES_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ENERGY_SITES_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManySites ({ commit }, { customerId = '', query = '', page = 1 }) {
    commit(ENERGY_SITES_FETCH_MANY_REQUEST)
    return window.axios.get('/energy-sites', { params: { customerId, query, page } }).then(response => {
      commit(ENERGY_SITES_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ENERGY_SITES_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchSite ({ commit }, id) {
    commit(ENERGY_SITES_FETCH_REQUEST)
    return window.axios.get(`/energy-sites/${id}`).then(response => {
      commit(ENERGY_SITES_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(ENERGY_SITES_FETCH_FAILURE, error)
      throw error
    })
  },
  saveSite ({ commit }, payload) {
    const path = payload.id ? `/energy-sites/${payload.id}` : '/energy-sites'
    const method = payload.id ? 'put' : 'post'

    commit(ENERGY_SITES_SAVE_REQUEST)
    return window.axios[method](path, payload).then(response => {
      commit(ENERGY_SITES_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(ENERGY_SITES_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(ENERGY_SITES_SAVE_FAILURE, { error })
      }
      throw error
    })
  }
}
